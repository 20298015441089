
import { cordawareIcon } from '@/components/icon/BpIcon';
import { Product, useVersionStore } from '@/stores/version';
import { defineComponent, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import BpVersionProductEditorVue from '../../../components/version/BpVersionProductEditor.vue';

export default defineComponent({
  name: "version-product-editor",
  components: {
    BpVersionProductEditor: BpVersionProductEditorVue,
  },
  setup() {
    ///-------------------------------------------------------------------
    /// ROUTER
    ///-------------------------------------------------------------------

    const router = useRouter();

    ///-------------------------------------------------------------------
    /// STORE
    ///-------------------------------------------------------------------

    const store = useVersionStore();

    const product = ref<Product>();

    function loadProduct() {
      if (router.currentRoute.value.name && (router.currentRoute.value.name as string).endsWith('edit')) {
        const doc = store.getById(router.currentRoute.value.params.id as string)
        product.value = doc as Product;
      }
    }

    watch(() => store.isLoading(), () => {
      if (!store.loaded || store.isLoading()) { return }
      loadProduct()
    }, { immediate: true });

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------
    return {
      cordawareIcon,
      product,
      store,
    };
  }
})
