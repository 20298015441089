import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "flex gap-x-md gap-y-sm",
  style: {"align-items":"baseline","flex-wrap":"wrap"}
}
const _hoisted_2 = {
  key: 0,
  style: {"font-size":"smaller"}
}
const _hoisted_3 = { style: {"display":"grid","gap":"var(--padding)","grid-template-columns":"repeat(auto-fill, minmax(200px, 1fr))"} }
const _hoisted_4 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "flex-column gap-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_alert = _resolveComponent("bp-alert")!
  const _component_bp_input = _resolveComponent("bp-input")!
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_label = _resolveComponent("bp-label")!
  const _component_bp_select = _resolveComponent("bp-select")!
  const _component_bp_fieldset = _resolveComponent("bp-fieldset")!
  const _component_bp_form_button = _resolveComponent("bp-form-button")!
  const _component_bp_card = _resolveComponent("bp-card")!

  return (_openBlock(), _createBlock(_component_bp_card, {
    "fill-height": "",
    "fill-width": ""
  }, {
    header: _withCtx(() => [
      (!_ctx.isEdit)
        ? (_openBlock(), _createBlock(_component_translate, {
            key: 0,
            tag: "h2"
          }, {
            default: _withCtx(() => _cache[15] || (_cache[15] = [
              _createTextVNode("New product")
            ])),
            _: 1
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_translate, {
              "render-html": "",
              tag: "h2",
              "translate-params": { product: _ctx.store.getName(_ctx.product) }
            }, {
              default: _withCtx(() => _cache[16] || (_cache[16] = [
                _createTextVNode("Edit product \"%{product}\"")
              ])),
              _: 1
            }, 8, ["translate-params"]),
            (!!_ctx.modelValue?.uuid)
              ? (_openBlock(), _createElementBlock("em", _hoisted_2, "(UUID: " + _toDisplayString(_ctx.product.uuid) + ")", 1))
              : _createCommentVNode("", true)
          ]))
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_bp_form_button, {
        type: "save",
        errors: _ctx.errors,
        action: _ctx.save
      }, null, 8, ["errors", "action"]),
      _createVNode(_component_bp_form_button, {
        type: "reset",
        action: _ctx.reset
      }, null, 8, ["action"]),
      _createVNode(_component_bp_form_button, {
        type: "cancel",
        to: { name: 'admin.version.overview' }
      })
    ]),
    default: _withCtx(() => [
      (_ctx.product)
        ? (_openBlock(), _createElementBlock("form", {
            key: JSON.stringify(_ctx.modelValue),
            class: "bp-version-product-editor__form",
            onSubmit: _cache[14] || (_cache[14] = _withModifiers(() => {}, ["prevent"]))
          }, [
            (!_ctx.isEdit && !_ctx.modelValue?.uuid)
              ? (_openBlock(), _createBlock(_component_bp_input, {
                  key: 0,
                  label: _ctx.$gettext('Unique identifier'),
                  onChangeValid: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setError('uuid', _ctx.$gettext('Unique identifier'), $event))),
                  pattern: "[a-z0-9_]",
                  maxlength: 200,
                  required: "",
                  modelValue: _ctx.product.uuid,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.product.uuid) = $event))
                }, {
                  text: _withCtx(() => [
                    _createVNode(_component_translate, null, {
                      default: _withCtx(() => _cache[17] || (_cache[17] = [
                        _createTextVNode("Only lowercase alphanumeric characters and underscores are allowed.")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_bp_alert, {
                      color: "yellow",
                      icon: "circle-exclamation"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_translate, { tag: "strong" }, {
                          default: _withCtx(() => _cache[18] || (_cache[18] = [
                            _createTextVNode("Once set this cannot be changed anymore!")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["label", "modelValue"]))
              : _createCommentVNode("", true),
            _createVNode(_component_bp_input, {
              label: _ctx.$gettext('Name'),
              onChangeValid: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setError('name', _ctx.$gettext('Name'), $event))),
              required: "",
              maxlength: 256,
              modelValue: _ctx.product.name,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.product.name) = $event))
            }, null, 8, ["label", "modelValue"]),
            _createVNode(_component_bp_input, {
              label: _ctx.$gettext('Registered'),
              text: _ctx.$gettext('This product is registered, add a superscript "®" to the name'),
              onChangeValid: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setError('registered', _ctx.$gettext('Registered'), $event))),
              flush: "",
              type: "checkbox",
              modelValue: _ctx.product.registered,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.product.registered) = $event))
            }, null, 8, ["label", "text", "modelValue"]),
            _createVNode(_component_bp_label, {
              label: _ctx.$gettext('Icon'),
              required: ""
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(['default', ..._ctx.customIcons], (value) => {
                    return (_openBlock(), _createBlock(_component_bp_input, {
                      key: value,
                      name: 'icon-selector-' + 'unique',
                      value: value.toLowerCase(),
                      seamless: "",
                      style: {"display":"inline-block"},
                      type: "radio",
                      "model-value": _ctx.product.icon,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.product.icon = $event.toLowerCase())),
                      onChangeValid: _cache[7] || (_cache[7] = ($event: any) => (_ctx.setError('icon', _ctx.$gettext('Icon'), $event)))
                    }, {
                      text: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(_component_bp_icon, {
                            icon: value === 'default'
                  ? _ctx.cordawareIcon()
                  : value,
                            style: {"font-size":"3rem"}
                          }, null, 8, ["icon"]),
                          (value !== 'default')
                            ? (_openBlock(), _createElementBlock("span", {
                                key: 0,
                                innerHTML: _ctx.store.getName(_ctx.store.getByUUIDPath(value)) || value
                              }, null, 8, _hoisted_5))
                            : (_openBlock(), _createBlock(_component_translate, { key: 1 }, {
                                default: _withCtx(() => _cache[19] || (_cache[19] = [
                                  _createTextVNode("Default")
                                ])),
                                _: 1
                              }))
                        ])
                      ]),
                      _: 2
                    }, 1032, ["value", "model-value"]))
                  }), 128))
                ])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_bp_select, {
              data: _ctx.parents,
              label: _ctx.$gettext('Parent product'),
              transform: _ctx.transformParent,
              onChangeValid: _cache[8] || (_cache[8] = ($event: any) => (_ctx.setError('parent_id', _ctx.$gettext('Parent product'), $event))),
              required: "",
              modelValue: _ctx.product.parent_id,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.product.parent_id) = $event))
            }, null, 8, ["data", "label", "transform", "modelValue"]),
            _createVNode(_component_bp_input, {
              label: _ctx.$gettext('Order'),
              onChangeValid: _cache[10] || (_cache[10] = ($event: any) => (_ctx.setError('order', _ctx.$gettext('Order'), $event))),
              type: "number",
              modelValue: _ctx.product.order,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.product.order) = $event))
            }, null, 8, ["label", "modelValue"]),
            _createVNode(_component_bp_select, {
              label: _ctx.$gettext('Meta keys'),
              onChangeValid: _cache[12] || (_cache[12] = ($event: any) => (_ctx.setError('meta_keys', _ctx.$gettext('Meta keys'), $event))),
              "onUpdate:modelValue": [
                _ctx.updateMetaKeys,
                _cache[13] || (_cache[13] = ($event: any) => ((_ctx.product.meta_keys) = $event))
              ],
              clearable: "",
              "create-new-on-enter": "",
              multiple: "",
              modelValue: _ctx.product.meta_keys
            }, null, 8, ["label", "onUpdate:modelValue", "modelValue"]),
            (_ctx.product.meta_keys.length > 0)
              ? (_openBlock(), _createBlock(_component_bp_fieldset, {
                  key: 1,
                  legend: _ctx.$gettext('Meta information'),
                  "intense-border": ""
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_6, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.product.meta_keys.sort((a, b) => a < b ? -1 : 1), (key) => {
                        return (_openBlock(), _createBlock(_component_bp_input, {
                          key: key,
                          indent: 1.125,
                          label: key,
                          onChangeValid: ($event: any) => (_ctx.setError(`meta.${key}`, key, $event)),
                          required: "",
                          modelValue: _ctx.product.meta[key],
                          "onUpdate:modelValue": ($event: any) => ((_ctx.product.meta[key]) = $event)
                        }, null, 8, ["label", "onChangeValid", "modelValue", "onUpdate:modelValue"]))
                      }), 128))
                    ])
                  ]),
                  _: 1
                }, 8, ["legend"]))
              : _createCommentVNode("", true)
          ], 32))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}