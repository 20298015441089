
import { $gettext } from 'vue-gettext';
import { Component, defaultProduct, Product, useVersionStore } from '@/stores/version';
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { cordawareIcon, customIcons, slashIcon } from '../icon/BpIcon';
import { defaultTranslated, getTranslated } from '@/translation';
import { isEqual, OptionalKeys } from '@/utils/object';
import { PopoverElement } from '../popover/BpPopoverMenu';
import { useFormErrors } from '@/utils/form-errors';
import { useRouter } from 'vue-router';
import BpToast from '../toast/BpToasts';
import clone from '@sahnee/clone';

export default defineComponent({
  name: 'bp-version-product-editor',
  props: {
    modelValue: Object as PropType<Product>,
    loading: Boolean,
  },
  setup(props) {
    ///-------------------------------------------------------------------
    /// ROUTER
    ///-------------------------------------------------------------------

    const router = useRouter();
    const isEdit = computed(() => router.currentRoute.value.name?.toString().endsWith('edit') || false);

    ///-------------------------------------------------------------------
    /// PRODUCT
    ///-------------------------------------------------------------------

    const initialProduct: OptionalKeys<Product, '_id' | '_rev'> = {
      ...defaultProduct,
      parent_id: (router.currentRoute.value.query.parent as string) || 'root',
    };

    const product = ref<OptionalKeys<Product, '_id' | '_rev'>>({ ...clone(initialProduct), ...props.modelValue ? clone(props.modelValue) : {} });
    watch(() => props.modelValue, reset, { immediate: true, deep: true });

    ///-------------------------------------------------------------------
    /// PARENT
    ///-------------------------------------------------------------------

    const parents = computed(() => {
      return store.getParents({ docTypes: ['version_product'], excludeIds: product.value._id, includeRoot: true })
        .map(parent => {
          return ({ ...parent, icon: (parent._id === 'root' ? slashIcon(['far', 'folder']) : ['far', 'folder']) })
        }) as PopoverElement[];
    });

    const transformParent = (parent: Product | Component) => {
      const path = store.getPathById(parent._id);
      if (path.length > 0) {
        return `${store.getName(parent)} <em style="opacity: 0.5;">(${path.map(parent => store.getName(parent)).join(' > ')})</em>`;
      } else if (parent._id === 'root') {
        return `<em style="opacity: 0.5;">(${store.getName(parent)})</em>`
      }
      return store.getName(parent) || '';
    };

    ///-------------------------------------------------------------------
    /// RESET
    ///-------------------------------------------------------------------

    function reset() {
      if (props.modelValue && isEqual(product.value, props.modelValue)) { return }

      // Reset meta key errors from meta keys added before resetting the form
      const metaKeys = Object.keys({ ...clone(initialProduct), ...props.modelValue ? clone(props.modelValue) : {} }.meta);
      const currentMetaKeys = Object.keys(product.value.meta);
      for (const key of currentMetaKeys) {
        if (!metaKeys.includes(key)) {
          errors.value.delete(`meta.${key}`);
        }
      }

      // Reset the product value
      product.value = { ...clone(initialProduct), ...props.modelValue ? clone(props.modelValue) : {} };
    }

    ///-------------------------------------------------------------------
    /// ERROR
    ///-------------------------------------------------------------------

    const { errors, setError } = useFormErrors<Product>()

    ///-------------------------------------------------------------------
    /// SAVE
    ///-------------------------------------------------------------------

    const store = useVersionStore();

    async function save() {
      let response;
      product.value.doc_type = 'version_product';
      if (product.value._id) {
        response = await store.update(product.value);
      } else {
        response = await store.create(product.value);
      }
      if (response?.success) {
        BpToast.show({
          color: 'green',
          title: product.value._id
            ? $gettext('Product successfully updated')
            : $gettext('Product successfully created'),
          content: product.value._id
            ? $gettext('The product was successfully updated.')
            : $gettext('The product was successfully created.'),
          icon: 'circle-check',
        });
        router.replace({ name: 'admin.version.overview' });
      } else {
        BpToast.show({
          color: 'red',
          title: product.value._id
            ? $gettext('Failed to update product')
            : $gettext('Failed to create product'),
          content: product.value._id
            ? $gettext('The product could not be updated: %{error}', { error: response?.error })
            : $gettext('The product could not be created: %{error}', { error: response?.error }),
          icon: 'circle-check',
        });
      }
    }

    ///-------------------------------------------------------------------
    /// META
    ///-------------------------------------------------------------------

    function updateMetaKeys(metaKeys: string[]) {
      const currentKeys = Object.keys(product.value.meta);
      for (const key of metaKeys) {
        if (!currentKeys.includes(key)) {
          product.value.meta[key] = defaultTranslated();
        }
      }
      for (const key of currentKeys) {
        if (!metaKeys.includes(key)) {
          delete product.value.meta[key];
          errors.value.delete(`meta.${key}`);
        }
      }
    }

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      cordawareIcon,
      customIcons,
      errors,
      getTranslated,
      isEdit,
      parents,
      product,
      reset,
      save,
      setError,
      slashIcon,
      store,
      transformParent,
      updateMetaKeys,
    }
  }
})
